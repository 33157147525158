import React, {Component, useEffect} from "react";
import { client } from "../Pages/Client";
import { withRouter } from "../Utils/withRouter";
import { Link } from "react-router-dom";

class Grades extends Component {
  constructor() {
    super();
    this.state = { grades: [] };
  }

  componentDidMount() {
    this.props.i18n.changeLanguage(this.props.match.params.locale);
    this.getData();
  }

  getData() {
    this.getGrades();
  }

  getCountryCode() {
    switch(process.env.REACT_APP_JUMP_MATH_ENVIRONMENT) {
      case 'us':
        return 'US';

      case 'ca':
        return 'CA';

      default:
        return 'ESP';
    }
  }

  async getCountry(contentfulClient, idName) {
    const countries = await contentfulClient.getEntries({
      content_type: 'countries',
      'fields.idName[match]': idName,
      limit: 1
    })
        .then(response => response.items);

    if(countries.length > 0)
      return countries[0];

    else
      return null;
  }

  async getCountryRegions(contentfulClient, country) {
    return await contentfulClient.getEntries({
      content_type: 'regions',
      'fields.country.sys.id[match]' : country.sys.id,
      include: 1 // We'll include 1 level of descendents in order to catch their related grades later
    })
        .then(response => response.items);
  }

  async getGrades() {

    const contentfulClient = client();
    const countryCode = this.getCountryCode();
    const country = await this.getCountry(contentfulClient,countryCode);
    const regions = await this.getCountryRegions(contentfulClient,country);

    const gradesId = new Set();
    const grades = [];

    for(const region of regions){
       for(const regionGrade of region.fields?.grades){
         if(! gradesId.has(regionGrade.sys.id)){
           gradesId.add(regionGrade.sys.id);
           grades.push(regionGrade);
         }
       }
    }

    this.setState({ grades });
  }

  render() {

    const { i18n, t } = this.props;
    
    return (
      <div>
        <div className="container my-5">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <h2>{t("navbar.grades")}</h2>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <table className="table table-striped table-bordered table-hover">
                <tbody>
                  {this.state.grades.map((grade, index) => {
                    //Carles
                    if (
                      grade.fields.name == "ESO1" ||
                      grade.fields.name == "ESO2" ||
                      grade.fields.name == "ESO3" ||
                      grade.fields.name == "EP6" ||
                      grade.fields.name == "Grade 7"
                    )
                      return (
                        <tr key={index}>
                          <td>
                            <Link className="" to={`../grade/${grade.sys.id}`}>
                              {grade.fields.name}
                            </Link>
                          </td>
                          <td className="text-end">
                            <Link
                              className="btn btn-primary btn-sm mx-2"
                              to={`../grade/${grade.sys.id}`}
                            >
                              {t("grados.ver")}
                            </Link>
                          </td>
                        </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Grades);
